@import "./fonts.scss";
@import "./variables.scss";
@import "./Components//navbar.scss";
@import "./Components//hero.scss";
@import "./Components//features.scss";
@import "./Components//footer.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $font1;
    font-weight: 400;
    transition: 0.4s ease;
    scroll-behavior: smooth;
}

a {
    display: inline-block;
    text-decoration: none;
    color: black;
}

button {
    cursor: pointer;
}

.container {
    width: 1600px;
    margin: 0 auto;
    padding: 80px 0;
}

@media (max-width: 1650px) {
    .container {
        width: 1420px;
    }
}

@media (max-width: 1450px) {
    .container {
        width: 1220px;
    }
}

@media (max-width: 1250px) {
    .container {
        width: 1020px;
    }
}

@media (max-width: 1050px) {
    .container {
        width: 820px;
    }
}

@media (max-width: 850px) {
    .container {
        width: 570px;
    }
}

@media (max-width: 570px) {
    .container {
        width: 100%;
    }
}
