footer {
    background-color: #222222;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 50px;

        .footer-center {
            .icons {
                a {
                    padding: 5px 7px 3px;
                    border: 2px solid white;
                    border-radius: 50px;
                    margin-right: 7px;

                    &:hover {
                        scale: 1.08;
                    }
                }
            }
        }

        .footer-right {
            display: flex;
            flex-direction: column;
            text-align: center;

            a {
                p {
                    font-size: 20px;
                    font-weight: 500;
                    padding: 10px 35px;
                    border-radius: 80px;
                    border: 3px solid white;
                    color: white;
                    cursor: pointer;

                    &:hover {
                        scale: 1.04;
                        background-color: rgb(226, 226, 226, 0.85);
                        color: black;
                    }
                }

                &:nth-child(2) {
                    margin-top: 10px;
                }
            }
        }
    }

    .made-by {
        color: white;
        text-align: center;
        padding-bottom: 20px;

        a {
            color: $blue;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media (max-width: 850px) {
    footer {
        .container {
            flex-direction: column;

            .footer-center {
                margin: 30px 0 50px;
            }
        }
    }
}
