.hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 70px;

    img {
        height: 300px;
    }

    h1 {
        font-size: 90px;
        font-weight: 400;
        font-family: $font2;
        color: $orange;
    }

    h3 {
        font-size: 33px;
        font-weight: 500;
    }

    .hero-buttons {
        margin-top: 40px;

        button {
            background-color: black;
            color: white;
            border: 3px solid black;
            padding: 18px 40px;
            border-radius: 50px;
            font-size: 20px;
            box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.4);

            &:hover {
                background-color: rgb(39, 39, 39);
                scale: 1.04;
            }

            &#buy-me-btn {
                background-color: #fcdd4b;
                color: black;
                font-weight: 500;
                margin-left: 20px;
                border: 3px solid #fcdd4b;

                &:hover {
                    background-color: #ffeb91;
                }
            }
        }
    }

    .random-images {
        position: relative;

        img {
            position: absolute;

            &.mic {
                top: -450px;
                left: -400px;
                width: 90px;
            }

            &.video-btn {
                top: -40px;
                left: -540px;
                width: 230px;
            }

            &.message {
                top: -450px;
                left: 300px;
                width: 100px;
            }
        }
    }
}

@media (max-width: 1100px) {
    .hero-section {
        .random-images {
            img.video-btn {
                top: -225px;
                left: -427px;
                width: 150px;
            }
        }
    }
}

@media (max-width: 850px) {
    .hero-section {
        h1 {
            font-size: 68px;
        }

        h3 {
            font-size: 25px;
        }

        .hero-buttons {
            button {
                padding: 14px 24px;
                font-size: 18px;
            }
        }

        .random-images {
            img.video-btn {
                display: none;
            }

            img.mic {
                top: -600px;
                left: -272px;
                width: 74px;
            }

            img.message {
                top: -213px;
                left: 205px;
                width: 75px;
            }
        }
    }
}

@media (max-width: 580px) {
    .hero-section {
        img {
            height: 250px;
        }

        h1 {
            font-size: 56px;
        }

        h3 {
            font-size: 22px;
        }

        .hero-buttons {
            margin-top: 30px;

            button {
                padding: 12px 18px;
            }
        }

        .random-images {
            img.video-btn {
                display: none;
            }

            img.mic {
                display: none;
            }

            img.message {
                display: none;
            }
        }
    }
}

@media (max-width: 450px) {
    .hero-section {
        img {
            height: 230px;
            width: 100%;
        }

        h3 {
            font-size: 18px;
        }

        .hero-buttons {
            button {
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 400px) {
    .hero-section {
        img {
            height: 210px;
        }

        h1{
            font-size: 50px;
        }

        h3 {
            font-size: 16px;
        }

        .hero-buttons {
            button {
                font-size: 15px;
            }
        }
    }
}
