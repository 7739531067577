.navbar-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    position: fixed;
    top: 0;
    width: 100vw;
    background-color: white;
    z-index: 200;

    .logo-container {
        display: flex;
        align-items: center;

        img {
            height: 60px;
        }

        p {
            font-weight: 600;
            font-size: 28px;
            margin-left: 20px;
        }
    }

    ul {
        list-style-type: none;

        li {
            display: inline-block;

            a {
                padding: 5px 10px 4px;
                margin: 0 15px;
                border-bottom: 4px solid $bg1;
                font-size: 14px;
                font-weight: 500;

                &:hover {
                    border-bottom: 4px solid $orange;
                }
            }
        }
    }

    button {
        font-size: 20px;
        color: $orange;
        font-size: 16px;
        font-weight: 700;
        box-shadow: 0px 0px 5px 1px $blue;
        padding: 12px 20px;
        border: none;
        background-color: $bg1;
        border-radius: 10px;

        &:hover {
            box-shadow: 0px 0px 8px 1px $blue;
        }
    }

    img#menu-icon {
        cursor: pointer;
        padding: 10px 0;
    }

    .mobile-menu-bar {
        position: fixed;
        top: -100vh;
        left: 0;
        background-color: black;
        color: white;
        height: 100vh;
        width: 100vw;
        z-index: 50;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
            top: 0;
        }

        .cross-icon {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        ul {
            list-style-type: none;

            li {
                display: block;
                text-align: center;

                a {
                    padding: 8px 10px;
                    margin-bottom: 10px;
                    border: none;
                    color: white;
                    font-size: 18px;
                }
            }

            a {
                display: block;
                text-align: center;

                button {
                    background-color: white;
                    color: black;
                    border: 3px solid black;
                    padding: 12px 20px;
                    border-radius: 50px;
                    font-size: 16px;
                    font-weight: 500;
                    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.4);
                    border: 3px solid white;
                    margin-top: 8px;
                    width: 200px;

                    &:hover {
                        background-color: rgb(236, 234, 231);
                        scale: 1.04;
                    }

                    &#buy-me-btn {
                        background-color: #fcdd4b;
                        color: black;
                        font-weight: 500;
                        border: 3px solid #fcdd4b;
                        margin-top: 50px;

                        &:hover {
                            background-color: #ffeb91;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .navbar-section {
        padding: 10px 30px;
    }
}

@media (max-width: 570px) {
    .navbar-section {
        .logo-container {
            img {
                height: 48px;
            }

            p {
                font-size: 25px;
                margin-left: 12px;
            }
        }
    }
}

@media (max-width: 450px) {
    .navbar-section {
        .logo-container {
            img {
                height: 45px;
            }
        }
    }
}
