.feature-container {
    margin: 150px auto 0 auto;

    h1 {
        text-align: center;
        font-weight: 600;
        font-size: 45px;
    }

    .feature-cards {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;

        .feature-card {
            position: relative;
            padding: 15px 20px 30px;
            width: 320px;
            border-radius: 24px 24px 10px 10px;
            text-align: center;
            background-color: white;

            img {
                background-color: rgb(233, 163, 77);
                padding: 10px;
                border-radius: 8px;
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(220%, -50%);
            }

            h3 {
                font-size: 24px;
                font-weight: 500;
                margin-top: 35px;
            }

            p {
                font-size: 18px;
                margin-top: 15px;
            }
        }
    }

    &.upcoming {
        margin: 50px auto 120px auto;
    }
}

@media (max-width: 1100px) {
    .feature-container {
        margin: 80px auto 0 auto;

        &.upcoming {
            margin: 0 auto 100px auto;
        }

        .feature-cards {
            flex-wrap: wrap;
            justify-content: space-around;

            .feature-card {
                margin: 0 20px;
            }

            .feature-card:nth-child(3) {
                margin-top: 80px;
            }
        }
    }
}

@media (max-width: 850px) {
    .feature-container {
        .feature-cards {
            flex-direction: column;
            align-items: center;

            .feature-card {
                margin-top: 70px;

                &:first-child{
                    margin-top: 0;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .feature-container {
        h1{
            font-size: 38px;
        }
    }
}
